.m-textarea {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &.-error {
        textarea {
            border-color: $red;
        }
    }

    textarea {
        width: 100%;
        height: 100px;
        outline: 0;
        border-radius: 4px;
        border: 1px solid $dark-blue-20;
        background-color: $white;
        padding: 15px 20px;
        color: $dark-blue;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        transition: 0.3s ease-in-out;
        filter: none;
        resize: none;

        @include scrollbar;

        &:focus {
            border-color: $dark-blue-20;
        }
    }

    &.-largeTextarea {
        textarea {
            height: 150px;
        }
    }

    &.-xxlTextarea {
        textarea {
            height: 250px;
        }
    }

    &.-flexibleTextarea {
        textarea {
            resize: vertical;
            min-height: 50px;
            transition: none;
        }
    }

    &.-disabled {
        opacity: 0.7;

        .removeItemIcon {
            cursor: default;
        }
    }

    label {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 21px;
    }
}
