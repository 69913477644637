.m-toggleButtons {
    &__wrapper {
        width: min-content !important;
        border: 1px solid $dark-blue-20;
        border-radius: 10px;
        padding: 5px;
        background-color: $white;

        .a-btn {
            padding: 10px;
        }
    }
}
