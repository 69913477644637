.m-transparentModal {
    opacity: 0;
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    background-color: $dark-blue-70;
    backdrop-filter: blur(2.5px);
    pointer-events: none;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.3s ease-in-out;
    @include scrollbar;

    &.-show {
        opacity: 1;
        pointer-events: initial;
    }

    .wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    &__image {
        width: 350px;
        height: 350px;
        border-radius: 50%;
        overflow: hidden;

        @include media-down(s) {
            width: 250px;
            height: 250px;
        }

        &--wrapper {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}
