.m-noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__noDataDescription {
        display: flex;
        max-width: 330px;
        text-align: center;
    }

    &__noDataDescriptionSmallScreen {
        display: flex;
        text-align: center;
        flex-wrap: wrap;
    }
}
