.m-dateTime {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    label {
        margin-bottom: 5px;
    }

    .react-datetime-picker__wrapper {
        width: 100%;
        height: 50px;
        outline: 0;
        border-radius: 6px;
        border: 1px solid $dark-blue-20;
        background-color: transparent;
        padding: 0 15px;
        color: $dark-blue;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        transition: 0.3s ease-in-out;

        .react-datetime-picker__inputGroup {
            padding: 0;
            min-width: 94px;
        }

        @include media-down(l) {
            .react-datetime-picker__calendar-button.react-datetime-picker__button,
            .react-datetime-picker__clear-button.react-datetime-picker__button {
                padding: 0;
            }
        }
    }

    .react-datetime-picker__calendar {
        inset: 100% auto auto 0px !important;
        z-index: 3;
        width: 307px;

        @include media-down(l) {
            width: 280px;
        }

        .react-calendar {
            width: 307px;
            border: none;
            border-radius: 4px;
            box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.08);
            font-family: $font-inter;

            @include media-down(l) {
                width: 280px;
            }
        }
    }

    .react-datetime-picker {
        &--disabled {
            background-color: transparent;
            color: $dark-blue;
        }
    }

    &.-timePicker {
        .react-datetime-picker__wrapper {
            position: relative;
            padding-right: 45px;
        }

        .timePickerIcon {
            position: absolute;
            right: 20px;
            top: 53px;
            transform: translateY(-50%);
            transition: 0.3s ease-in-out;
            overflow: visible;
            cursor: pointer;
        }
    }

    &.-error {
        .react-datetime-picker__wrapper {
            border: 1px solid $red;
        }
    }

    &.-disabled {
        opacity: 0.7;
    }

    &.-empty {
        select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__month {
            color: #7d7e8e;

            option {
                color: black;
            }
        }
    }

    select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__month,
    select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__amPm {
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
        appearance: none;
        // width: 30px !important;
        outline: none;

        option[value=''] {
            display: none;
        }
    }

    select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__month {
        width: 25px;
    }

    &.-may {
        select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__month {
            width: 27px;
        }
    }

    &.-jan,
    &.-feb,
    &.-apr {
        select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__month {
            width: 24px;
        }
    }

    &.-jul {
        select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__month {
            width: 21px;
        }
    }

    /* For IE10 */
    select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__month::-ms-expand {
        display: none;
    }

    input.react-datetime-picker__inputGroup__input,
    select.react-datetime-picker__inputGroup__input {
        outline: none;
    }

    .react-calendar__month-view__weekdays__weekday {
        abbr {
            text-decoration: none;
            text-transform: capitalize;
        }
    }

    .react-datetime-picker__inputGroup__input:invalid {
        background-color: transparent;
    }

    button.react-datetime-picker__calendar-button,
    button.react-datetime-picker__clear-button,
    button.react-calendar__tile {
        outline: none;
    }

    button.react-calendar__navigation__label,
    button.react-calendar__navigation__arrow {
        outline: none;
    }

    button.react-calendar__tile {
        color: $dark-blue;
    }

    button.react-calendar__tile:disabled {
        color: $ligth-gray;
    }

    .react-calendar__navigation button:disabled {
        background-color: white;
        color: $ligth-gray;
        svg path {
            stroke: $ligth-gray;
        }
    }

    .react-calendar__tile--now {
        &:not(:disabled) {
            background: none;

            &:hover {
                background-color: #e6e6e6;
            }
        }

        &--disabled {
            background-color: #f0f0f0 !important;

            &:hover {
                background-color: #f0f0f0 !important;
            }
        }
    }

    .react-calendar__tile--active,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
        background-color: $light-blue-05;

        &:hover {
            background-color: $light-blue-05;
        }
    }

    .react-calendar__tile:disabled {
        background-color: white;
    }

    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
        color: $dark-blue-20;
    }

    .react-calendar__tile.react-calendar__year-view__months__month,
    .react-calendar__tile.react-calendar__decade-view__years__year {
        padding: 1.4em 0.4em;
    }

    .react-datetime-picker__clock {
        position: absolute;
        inset: 78px auto auto 0px;
        padding: 20px 15px;
        width: fit-content;
        height: fit-content;
        display: flex;
        gap: 10px;
        border: none;
        border-radius: 4px;
        box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.08);
        z-index: 3;

        .timeInputSeparator {
            display: flex;
            height: 40px;
            margin-top: 32px;
        }

        .m-inputGroup {
            width: 80px;

            input {
                height: 40px;
                margin-top: 0;
                padding: 15px 10px;
            }

            label {
                font-size: 12px;
                line-height: 18px;
                font-weight: 600;
            }
        }
    }

    &.-dropup {
        .react-datetime-picker__calendar {
            inset: -281px auto auto 0px !important;
        }
    }
}
