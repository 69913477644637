.m-project {
    padding-left: 0;
    padding-right: 0;

    @include media-up(l) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @include media-down(m) {
        position: fixed;
        z-index: 6;
        top: 0;
        left: 0;
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 30px;
        padding-bottom: 30px;

        @include scrollbar;
    }

    @include media-down(s) {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .removeItemIcon {
        z-index: 2;
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .-payRate {
        .a-checkbox {
            margin-left: 20px;

            label {
                white-space: nowrap;
            }
        }

        @include media-down(m) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .a-checkbox {
                margin-top: 0;
                margin-bottom: 15px;
            }
        }
    }

    .-buttons {
        .a-btn {
            width: 100%;
        }
    }

    .a-infoList {
        .-label {
            @include media-up(m) {
                flex: 0 0 20%;
                max-width: 20%;
            }
        }

        .-values {
            @include media-up(m) {
                flex: 0 0 75%;
                max-width: 75%;
            }

            @include media-down(s) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    .-projectButtons {
        @include media-down(xs) {
            flex-direction: column;
            height: 100%;

            .-prevButton,
            .-nextButton {
                width: 100%;

                margin-top: 15px;
            }
        }
    }

    .-titleAndButton {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
