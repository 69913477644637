.m-header {
    z-index: 6;
    position: fixed;
    top: 0;
    width: 100%;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: $white;
    border-bottom: 1px solid $dark-blue-20;
    color: $dark-blue;
    transition: 0.3s ease-in-out;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .-links {
            display: flex;
            width: 100%;
            overflow: hidden;

            & > div {
                transition: 0.3s ease-in-out;
            }

            &.-movedLeft {
                & > div {
                    transform: translateX(-700%);
                }
            }
        }
    }

    .-topLevel {
        width: 100%;

        &.-fixedSidebar {
            padding-left: $sidebar-width;

            .-mainLogo {
                position: absolute;
                left: 30px;
            }
        }
    }

    .-mainLogo {
        margin-right: 30px;
        transition: 0.3s ease-in-out;

        &.-movedLeft {
            margin-left: -100%;
        }
    }

    .-exitSearch {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
    }

    &__notificationsLabel {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-right: 20px;

        svg circle {
            opacity: 0;
            visibility: hidden;
        }

        &.-active {
            svg circle {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-size: 20px;
        white-space: nowrap;

        img {
            width: 150px;
            height: 30px;
            margin-right: 20px;
        }

        span {
            @include media-down(s) {
                display: none;
            }
        }
    }

    &__nav,
    &__leftPart {
        display: flex;
        align-items: center;
    }

    &__main {
        display: flex;
        align-items: center;

        .-searchAndExit {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include media-down(m) {
            justify-content: flex-end;
            width: 100%;

            .-searchAndExit {
                width: 100%;

                & > div:first-of-type {
                    width: 100%;
                }
            }
        }

        .-chatIcon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            min-width: 36px;
            height: 36px;
            border-radius: 100%;
            background-color: $white;
            transition: 0.3s ease-in-out;

            @include media-down(xl) {
                margin-left: 10px;
            }

            &.-withoutIcon {
                svg {
                    margin-top: 2px;
                }
            }

            .a-iconBtn div {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    margin-right: 0;
                }
            }

            &.-active {
                background-color: $light-blue-05;
            }

            .chatPro {
                position: absolute;
                top: -1px;
                right: -2px;
            }
        }
    }
}
