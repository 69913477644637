.m-radioGroupContainer {
    z-index: 3;
    margin-bottom: 15px;

    &.-disabled {
        opacity: 0.7;

        label span {
            opacity: 0.7;
        }

        .m-radioGroup label {
            cursor: default;
        }

        .removeItemIcon {
            cursor: default;
        }
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        justify-content: center;
        margin-top: 10px;

        @include media-down(m) {
            gap: 10px;
        }

        &.-descriptionAttribute {
            & > div {
                position: relative;

                &:not(:last-of-type) {
                    padding-right: 30px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 1px;
                        height: 100%;
                        border-right: 1px dashed $dark-blue-20;
                    }
                }

                &:not(:first-of-type) {
                    padding-left: 30px;
                }
            }

            @include media-down(l) {
                flex-direction: column;
                align-items: flex-start;

                & > div {
                    width: 100%;
                    padding-top: 10px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: unset !important;
                        right: 0;
                        bottom: 0;
                        width: 100% !important;
                        height: 1px !important;
                        border-bottom: 1px dashed $dark-blue-20;
                        border-right: none !important;
                    }

                    &:not(:last-of-type) {
                        padding-right: 0;
                        padding-bottom: 10px;
                    }

                    &:last-of-type {
                        &::after {
                            border-bottom: none !important;
                        }
                    }

                    &:not(:first-of-type) {
                        padding-left: 0;
                    }
                }
            }

            .-disabled {
                opacity: 0.5;
            }

            label {
                align-items: baseline !important;
            }
        }

        &.-column {
            flex-direction: column;

            label {
                align-items: baseline !important;

                & > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    text-align: left;
                }
            }

            .m-radioGroup {
                border-radius: 5px;
                border: 1px solid $dark-blue-20 !important;
                padding: 10px 15px !important;
                transition: 0.3s ease-in-out;
            }

            .-radioGroupElements {
                padding: 0 !important;

                &::after {
                    display: none;
                }

                &.-checkedItem {
                    .m-radioGroup {
                        border-color: $light-blue !important;
                        background-color: $light-blue-05;

                        .radio {
                            border-color: $light-blue;

                            &::after {
                                background: $light-blue;
                            }
                        }
                    }
                }
            }
        }

        .-disabled {
            opacity: 0.7;

            .m-radioGroup {
                label {
                    cursor: default;
                }
            }
        }
    }

    .m-radioGroup {
        display: flex;

        input {
            display: none;
        }

        label {
            display: flex;
            align-items: center;
            cursor: pointer;

            .radio {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 16px;
                min-height: 16px;
                width: 16px;
                height: 16px;
                margin-right: 5px;
                border: 1px solid $dark-blue-20;
                border-radius: 50%;
                background-color: $white;
                transition: 0.3s ease-in-out;

                &::after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: $dark-blue;
                    transform: scale(0);
                    transition: 0.3s ease-in-out;
                }
            }
        }

        input:checked + label {
            .radio {
                &::after {
                    transform: scale(1);
                }
            }
        }
    }
}
