.a-actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-right: 0 !important;
        cursor: pointer;
    }

    &__buttons {
        z-index: 3;
        position: absolute;
        top: 100%;
        right: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid $dark-blue-05;
        background: $white;
        box-shadow: 0px 5px 5px 0px $dark-blue-05;
        opacity: 0;
        transform: translateY(-5px);
        visibility: hidden;
        transition: 0.3s ease-in-out;
        width: max-content;

        @include media-down(xs) {
            max-width: 270px;
        }

        .a-btn {
            padding: 10px 20px;
            width: 100%;

            &:hover {
                background-color: $silver;
            }

            &.-tertiary {
                &:hover {
                    color: $dark-blue;
                }
            }

            &:disabled {
                svg {
                    cursor: not-allowed;
                }
            }

            div {
                justify-content: flex-start;
            }
        }

        &.-active {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }
    }
}

.a-stepCard__header .a-actions {
    &.status-dropdown {
        z-index: 2;

        &:has(.a-actions__buttons.-active) {
            z-index: 3;
        }
    }
}
