.m-inputGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;

    &.-icon {
        input {
            z-index: 2;
            position: relative;
            background-color: transparent;
            padding-right: 45px;
        }
    }

    &.-error {
        input {
            border-color: $red;
        }
    }

    &.-hasValue {
        input {
            background-color: $white;
        }
    }

    &.-disabled {
        opacity: 0.7;

        .errorMessage {
            display: none;
        }

        .removeItemIcon {
            cursor: default;
        }
    }

    input {
        width: 100%;
        margin-top: 5px;
        background-color: $white;
        outline: 0;
        transition: 0.3s ease-in-out;
        filter: none;

        &:invalid {
            box-shadow: none;
        }

        &:focus {
            border-color: $dark-blue-20;

            ~ svg {
                stroke: $dark-blue-20;
            }
        }

        &::placeholder {
            opacity: 1;
            color: $dark-blue-60;
        }
    }

    &.-required {
        label {
            &::after {
                position: relative;
                content: '*';
                color: $red;
                right: -5px;
            }
        }
    }

    label {
        color: $dark-blue-80;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.-hidden {
            opacity: 0;
            visibility: hidden;
        }
    }

    svg {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s ease-in-out;
        overflow: visible;
    }

    &.-tableSearch {
        margin-bottom: 0;

        .a-input {
            margin-top: 0;
            padding-left: 50px;
        }

        svg {
            right: unset;
            left: 20px;
        }
    }

    &.-units {
        label {
            width: 100%;
            min-width: 100%;
        }

        .a-input {
            padding-right: 65px;
        }

        .m-inputGroup__units {
            position: absolute;
            top: 41px;
            right: 10px;
        }
    }

    &.-password {
        .a-iconBtn {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
        }
    }

    &.-overflowVisible label {
        overflow: visible;
    }
}

.-unitsWrapper {
    @include media-down(s) {
        padding-right: 0;
    }
}
