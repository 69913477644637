.m-signature {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &.-error {
        canvas {
            border-color: $red;
        }
    }

    canvas {
        outline: 0;
        border-radius: 4px;
        border: 1px solid $dark-blue-20;
        background-color: $white;
    }

    label {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 21px;
    }

    .clear {
        display: flex;
        justify-content: flex-end;

        .a-btn {
            width: auto;
        }
    }

    &.-disabled {
        opacity: 0.7;

        canvas {
            pointer-events: none;
        }
    }

    &.-preview {
        margin-bottom: 0;

        canvas {
            outline: 0;
            border: none;
        }
    }
}
