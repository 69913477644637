.m-gallery {
    width: 100%;

    &__box {
        padding: 20px 30px 20px 30px;

        border-radius: 10px;
        border: 1px dashed $light-blue-60;
        background-color: $light-blue-05;
        max-width: 100%;

        &.-noPhotos {
            min-height: 580px;

            .m-gallery__box--content {
                min-height: 216px;
            }
        }

        &.-uploading {
            position: fixed;
            opacity: 1;
            z-index: 10;
            border: 1px solid #eee;
            background: white;
            bottom: 0;
            right: 20px;
            max-width: calc(100% - 40px);

            width: auto;
            max-width: 500px;

            @include media-down(s) {
                max-width: 300px;
            }

            .-nameAndSize {
                overflow: hidden;
                width: 100%;
                flex-wrap: nowrap;
                display: flex;
            }

            .-fileName {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .-textAndButton {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .-barAndButton {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            .-progressBar {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;

                .-track,
                .-progress {
                    width: 100%;
                    height: 4px;
                    border-radius: 2px;
                    background: $dark-blue-20;
                }

                .-progress {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: $light-blue;
                }
            }
        }
    }

    &__selectActive {
        @include media-down(m) {
            flex-direction: column;

            .space-between-buttons {
                justify-content: space-between;
                border-bottom: 1px solid $dark-blue-20;
                padding-bottom: 10px;
            }
        }
    }
}

.ReactGridGallery_tile:has(.ReactGridGallery_tile-viewport[style*='margin: 16px']) {
    svg {
        fill: $light-blue;
    }
}

.ReactGridGallery_tile svg {
    visibility: visible;
    opacity: 1;
}

.ReactGridGallery_tile-icon-bar {
    height: 100% !important;
    transition: 0.3s ease-in-out;
    z-index: -1;
}

.-allowSelection .ReactGridGallery_tile-icon-bar {
    z-index: 2;
    background: $dark-blue-10;
}

.ReactGridGallery_tile {
    border-radius: 5px;
    overflow: hidden;
}

.yarl__toolbar {
    width: 100%;
    background-color: $light-blue;
}

.yarl__container,
.yarl__thumbnails_container.yarl__flex_center {
    background-color: rgba($black, 0.5) !important;
}

.yarl__button:focus {
    outline: none;
}

.yarl__thumbnails_bottom .yarl__thumbnails_vignette,
.yarl__thumbnails_top .yarl__thumbnails_vignette {
    display: none;
}

.yarl__thumbnails_thumbnail_placeholder {
    display: none;
}

.yarl__flex_center.yarl__thumbnails_thumbnail {
    background-color: $white;
}

.yarl__carousel.yarl__carousel_with_slides {
    margin-top: 120px;
}
