.a-alert {
    z-index: 99;
    position: fixed;
    top: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 80vw;
    border-radius: 10px;
    border-left: 5px solid $dark-blue;
    background-color: #d6e9f1;
    padding: 20px;
    font-size: 18px;
    line-height: 28px;
    opacity: 1;
    cursor: pointer;

    @include media-up(l) {
        min-width: 500px;
    }

    @include media-up(m) {
        max-width: 600px;
    }

    @include media-down(s) {
        min-width: unset;
        width: 80vw;
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 45px);

        .-text {
            display: flex;
            flex-direction: column;
            width: calc(100% - 45px);

            span {
                word-wrap: break-word;
            }

            .-type {
                text-transform: capitalize;
            }
        }
    }

    &.-success {
        border-color: $green;
        background-color: #b3dcce;

        .removeItemIcon {
            &::after,
            &::before {
                background-color: $green;
            }
        }
    }

    &.-warning {
        border-color: $orange;
        background-color: #feccb5;

        .removeItemIcon {
            &::after,
            &::before {
                background-color: $orange;
            }
        }
    }

    &.-notification,
    &.-error {
        border-color: $light-blue;
        background-color: #d6e9f1;

        .removeItemIcon {
            &::after,
            &::before {
                background-color: $light-blue;
            }
        }
    }

    &.-center {
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }

    svg {
        min-width: 32px;
        margin-right: 10px;
    }
}
