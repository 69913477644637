.a-oneLineSelectGroup {
    .m-selectGroup__title {
        margin-bottom: 10px;
    }

    .m-selectGroup__field {
        display: flex;
        width: 100%;

        @include media-down(l) {
            flex-wrap: wrap;
        }

        .-firstField,
        .-secondFieldAndButton {
            width: 100%;

            & > div {
                display: flex;
                flex-direction: column;
            }

            label {
                margin-bottom: 5px;
            }
        }

        .-firstField {
            margin-right: 20px;

            @include media-down(l) {
                margin-top: 0;
                margin-bottom: 5px;
                margin-right: 0;
            }
        }

        .-secondFieldAndButton {
            & > div {
                width: 100%;
            }

            @include media-down(s) {
                flex-direction: column;
            }

            .a-btn {
                margin-left: 20px;
                margin-bottom: 10px;
                white-space: nowrap;

                @include media-down(l) {
                    margin-bottom: 10px;
                }

                @include media-down(s) {
                    margin-top: 10px;
                    margin-bottom: 0;
                    margin-left: 0;
                    width: 100%;
                }
            }

            &.-multiselect {
                .a-btn {
                    margin-top: 5px;
                }
            }
        }

        .m-selectGroup__container,
        .m-multiselectGroup {
            margin-top: 5px;
        }
    }
}
