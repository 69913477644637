.a-settingsTabs {

    &__item {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 100px;
        padding: 10px 20px 10px 30px;
        color: $dark-blue;
        transition: .3s ease-in-out;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;

        &.-active {
            background-color: $dark-blue-05;

            .a-settingsTabs__subItemsIcon {
                &::after {
                    transform: translate(-50%, -50%) rotate(135deg);
                }
            }
        }

        &:hover {
            background-color: $dark-blue-03;
        }
    }

    &__itemLabel {
        opacity: 1;
        width: 100%;
        color: $dark-blue;
        overflow: hidden;
        white-space: nowrap;
        transition: .3s ease-in-out;
    }

    &__toggler {
        z-index: 2;
        position: absolute;
        top: 30px;
        right: -17px;
        display: block;
        width: 30px;
        height: 30px;
        border: 1px solid $dark-blue-20;
        border-radius: 50%;
        background-color: $white;
        transition: .3s ease-in-out;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 7px;
            height: 7px;
            border: 1.5px solid $dark-blue-20;
            border-top: 0;
            border-right: 0;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: .3s ease-in-out;
        }
    }

    &__subItemsIcon {
        position: absolute;
        right: 25px;
        transition: .3s ease-in-out;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 7px;
            height: 7px;
            border: 1.5px solid $dark-blue;
            border-top: 0;
            border-right: 0;
            transition: .3s ease-in-out;
        }
    }

    &__itemContainer {
        display: flex;
        flex-direction: column;

        .a-settingsTabs__subitemLabel {
            margin: 10px 20px 10px 30px;
            pointer-events: none;
        }

        &.-withSubItems {
            .a-settingsTabs__item.-active {
                background-color: transparent;
            }
        }

        &.-active {
            height: max-content;

            .a-settingsTabs__subitem {
                opacity: 1;
                max-height: 40px;
                transition: .3s ease-in-out;

                svg {
                    pointer-events: all;
                }

                &::before {
                    opacity: 1;
                    transition: .3s ease-in-out;
                }
            }

            .a-settingsTabs__subItemsIcon {
                &::after {
                    transform: translate(-50%, -50%) rotate(315deg);
                }
            }

            .a-settingsTabs__subitemLabel {
                pointer-events: all;
            }
        }
    }

    &__subitems {
        display: flex;
        flex-direction: column;
        transition: .3s ease-in-out;
    }

    &__subitem {
        opacity: 0;
        position: relative;
        display: flex;
        align-items: center;
        max-height: 0;
        border-radius: 100px;
        transition: .3s ease-in-out;
        white-space: nowrap;
        cursor: pointer;

        &.-active {
            background-color: $dark-blue-05;
        }

        &:hover {
            background-color: $dark-blue-03;
        }
    }

    &__subitemLabel {
        opacity: 1;
        display: block;
        width: auto;
        color: $dark-blue;
        transition: .3s ease-in-out;
    }
}