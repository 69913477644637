.a_customOptionItem {
    &__imageWrapper {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        border: 1px solid $dark-blue-20;
        background-color: $white;
        border-radius: 100%;

        .-image {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &.-larger {
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
        }
    }
}
