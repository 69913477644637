.m-404 {
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    background: $white;
    padding: 100px 10px;

    @include media-down(m) {
        justify-content: center;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 760px;
        height: 550px;
        background-image: url(../../../images/404.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transform: translate(-50%, -50%);

        @include media-down(l) {
            width: 90%;
        }
    }

    &__logo,
    &__404 {
        z-index: 1;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__logo {
        width: 200px;
        height: 200px;
        background-image: url(../../../images/logo-dark.svg);

        @include media-down(m) {
            width: 100px;
            height: 100px;
        }
    }

    &__404 {
        width: 550px;
        height: 205px;
        background-image: url(../../../images/404-text.svg);

        @include media-down(m) {
            width: 300px;
            height: 110px;
            margin-top: 50px;
        }
    }

    &__textBlock {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .a-btn {
            margin-top: 20px;
        }
    }
}
