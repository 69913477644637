.a-positionCard {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    border: 1px solid $dark-blue-20;
    background-color: $white;
    padding: 20px;

    @include media-down(s) {
        flex-direction: column;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: calc(100% - 80px);
        margin-left: 20px;

        @include media-down(s) {
            width: 100%;
            margin-top: 15px;
            margin-left: 0;
        }

        .-titleAndImage {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 5px;

            .-buttons {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
            }

            .a-positionCard__image {
                max-height: 20px;
                max-width: 20px;
                min-height: 20px;
                min-width: 20px;
                margin-right: 10px;
                border-radius: 0;
                background-color: transparent;

                img {
                    transform: scale(3) !important;
                }
            }
        }

        .-values {
            display: flex;
            flex-wrap: wrap;

            .a-status {
                margin-right: 5px;
            }
        }

        .-payRate {
            gap: 5px;

            .-infoLabel {
                margin-right: 4px;
            }
        }

        .-descriptionText {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-word;
        }
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        max-height: 50px;
        max-width: 50px;
        min-height: 50px;
        min-width: 50px;
        border-radius: 10px;
        background-color: $light-blue-05;
        overflow: hidden;

        @include media-down(s) {
            max-height: 80px;
            min-height: 80px;
            max-width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            max-height: 110px;
            object-fit: contain;
            object-position: center;
            transform: scale(1.7);
        }
    }

    &__button {
        display: flex;

        .a-btn {
            white-space: nowrap;
        }

        @include media-down(s) {
            width: 100%;

            .a-btn {
                width: 100%;
            }
        }
    }

    .-buttons {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 1299px) {
            display: flex;
            width: 100%;
            gap: 15px;
            justify-content: flex-end;
            margin-top: 20px;

            .a-btn {
                width: max-content;
            }
        }

        @include media-down(s) {
            flex-direction: column;
            margin-top: 10px;

            & > div {
                width: 100%;
            }

            .a-btn {
                margin-top: 0;
                width: 100%;
            }
        }
    }

    &.-isSideCard {
        .-titleAndImage {
            justify-content: flex-start;
            align-items: center;
        }

        .a-positionCard__content {
            width: 100%;
            margin-top: 0;
            margin-left: 0;
        }

        .-buttons {
            margin-top: 10px;
        }

        .a-btn.-tertiary {
            height: 20px !important;
        }

        .-payRate {
            &.-aboutPosition {
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .-buttons {
            justify-content: center !important;
        }
    }
}

.a-jobCard {
    &__image {
        width: 50px;
        height: 50px;
        min-height: 50px;
        min-width: 50px;
        max-width: 50px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: $light-blue-05;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
            transform: translate(1.5);
        }
    }
}
