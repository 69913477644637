.m-dataAccessControl {
    &__titleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-down(s) {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            .a-btn {
                width: 100%;
            }
        }
    }
}
