.m-rejectionTemplateItem {
    position: relative;
    cursor: pointer;

    .-editButton {
        z-index: 2;
    }

    .-clickableOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}
