.m-step {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 6px);
    background-color: $white;
    padding-top: 20px;

    @include media-down(m) {
        height: auto;
        padding-top: 0;
    }

    &__backButton {
        .a-btn {
            width: max-content !important;
        }

        .-fakeSpace {
            width: 90px;
        }

        @include media-down(m) {
            margin-top: 0 !important;
        }

        @include media-down(s) {
            flex-direction: column;
        }
    }

    &__content-wrapper,
    &__content {
        height: 100%;
    }

    &__content-wrapper {
        @include media-up(m) {
            min-height: 70vh;
        }
    }

    &__content {
        min-height: 70vh;

        @include media-down(m) {
            min-height: 100%;
        }

        .-contentElements {
            width: 100%;
            min-height: 80%;

            &.-skillsOnboarding {
                .a-stepCard {
                    .-info:last-of-type {
                        border: none;
                    }
                }
            }
        }
    }

    &__buttons {
        display: flex;
        background-color: $white;

        .a-btn {
            width: 200px;
            min-width: 200px;

            @include media-down(s) {
                width: 100%;
                min-width: 130px;
            }
        }

        @include media-down(m) {
            z-index: 9;
            position: fixed;
            bottom: 0;
            left: 0;
            flex-wrap: wrap;
            width: 100vw;
            border-top: 1px solid $dark-blue-20;
            padding: 20px !important;

            .-backButton,
            .-middleButton,
            .-nextButton {
                width: 165px;

                @include media-down(xs) {
                    width: 150px;
                }
            }

            .-nextButton {
                .a-btn,
                .-reject {
                    @include media-down(m) {
                        width: 50%;
                    }
                }
            }

            .-middleButton {
                width: 100%;
            }
        }

        .-pt50 {
            padding-top: 30px;
        }

        &.-withError {
            flex-wrap: wrap;

            .-errorMesage {
                order: -2;
                width: 100%;
            }
        }
    }

    .m-radioGroupContainer__options {
        justify-content: flex-start;
    }

    form {
        width: 100%;
    }

    .-buttons {
        .a-btn {
            white-space: nowrap;
        }

        @include media-down(m) {
            .-mt40 {
                margin-top: 20px;
            }
        }
    }

    .-description {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &Text {
            max-width: 80vw;

            @include media-up(xl) {
                max-width: 580px;
            }
        }
    }

    &__list {
        &.-rightSeparator {
            position: relative;

            @include media-up(l) {
                &::after {
                    content: '';
                    position: absolute;
                    top: 60px;
                    right: 0;
                    width: 1px;
                    height: calc(100% - 60px);
                    border-right: 1px dashed $dark-blue-20;
                }
            }
        }
    }

    .m-addAndUpload__box .a-file p {
        min-width: unset;
    }

    .-firstColumn {
        position: relative;

        &::after {
            position: absolute;
            top: 40px;
            right: 0;
            width: 1px;
            height: calc(100% - 40px);
            border-right: 1px dashed $dark-blue-20;
            background-color: transparent;

            @include media-up(m) {
                content: '';
            }
        }

        @include media-up(xl) {
            padding-right: 25px;
        }
    }

    .-secondColumn {
        @include media-up(xl) {
            padding-left: 25px;
        }

        .a-stepCard {
            .m-multiselectGroup__items {
                margin-bottom: 15px;
            }
        }
    }

    .-separatorLine:nth-last-of-type(2) {
        display: none !important;
    }

    .a-stepCard__footer {
        @include media-up(xs) {
            .a-btn {
                width: unset;
            }
        }
    }
}

.m-profile {
    padding-top: 15px;

    .m-inputGroup svg {
        top: 53px;
    }

    .max-width {
        max-width: 600px;
        display: flex;
        flex-direction: column;
    }

    .a-btn {
        margin-bottom: 10px;

        &.-primary {
            margin-top: 30px;
        }
    }

    form {
        max-width: 600px;
        margin: 0 auto;
    }

    .m-selectGroup {
        &.-multiple {
            margin-bottom: 20px;
        }

        &__field {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            width: 100%;

            & > div {
                &:first-of-type {
                    width: 100%;
                }

                &:last-of-type {
                    width: calc(70% - 10px);
                }
            }

            .a-btn {
                margin-left: 10px;
                width: 30%;
            }
        }
    }
}

.m-profileTabs {
    .m-radioGroupContainer__options {
        justify-content: flex-start;
    }

    &.-skills {
        .m-multiselectGroup__items {
            margin-bottom: 15px;
        }

        .m-addAndUpload.-disabled {
            display: none;
        }

        .-experienceCard .a-status {
            @include media-down(xl) {
                max-width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            @include media-down(xs) {
                max-width: 215px;
            }
        }
    }
}

.-diveRecordHeader {
    top: 0;
    border-bottom: 1px solid $dark-blue-20;
    transform: translateY(-100%);

    .-backBtn {
        position: absolute;
    }

    .-singleStep {
        .-name {
            opacity: 0;
            visibility: visible;
            font-size: 0;
            transition: 0.3s ease-in-out;
        }

        &:hover,
        &.-active {
            .-name {
                opacity: 1;
                visibility: visible;
                font-size: 14px;
            }
        }
    }
}

.-diveRecordFooter {
    bottom: 0;
    border-top: 1px solid $dark-blue-20;
    transform: translateY(100%);

    @include media-down(m) {
        .m-step__buttons {
            padding-top: 0 !important;
        }
    }
}

.-diveRecordHeader,
.-diveRecordFooter {
    z-index: 11;
    position: fixed;
    left: 0;
    width: 100%;
    background-color: $white;
    transition: 0.3s ease-in-out;

    &.-active {
        transform: translateY(0);
    }
}
