.m-rejectionForm {
    border: 1px solid $dark-blue-20;
    border-radius: 10px;
    background-color: $white;

    &__infoBox {
        background-color: $light-blue-05;
        padding: 20px;
        border-radius: 10px;
    }

    &__title {
        border-bottom: 1px solid $dark-blue-20;
        padding: 20px;
        gap: 10px;

        .m-inputGroup {
            margin: 0;

            .a-input {
                padding: 0;
                margin: 0;
                border: none;
                height: 21px;
                font-size: 16px;
                font-weight: 500;
            }

            .errorMessage {
                position: absolute;
                bottom: -15px;
                left: -48px;
            }
        }
    }

    &__body {
        padding: 20px;

        .m-textarea {
            margin-bottom: 0;

            textarea {
                border: none;
                padding: 0;
                height: 340px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                color: $dark-blue-60;

                @include media-down(l) {
                    height: 100px;
                }
            }
        }
    }

    &__actions {
        padding: 20px;
        border-top: 1px solid $dark-blue-20;

        .left,
        .right {
            gap: 20px;

            .a-btn.-left svg {
                margin-right: 5px;
            }
        }
    }
}
