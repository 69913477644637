.a-verificationCode {
    display: flex;
    gap: 10px;

    input {
        width: 40px;
        height: 50px;
        min-width: 40px;
        min-height: 50px;
        text-align: center;
        border: 1px solid $dark-blue-20;
        border-radius: 4px;

        &:focus-visible {
            outline: none;
            border: 1px solid $light-blue;
        }
    }
}
