.m-main {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    margin-left: $sidebar-closed-width;
    background-color: $dark-blue-05;
    padding: $header-height 0 30px 0;
    transition: 0.5s ease-in-out;

    &.-sidebarIsOpen {
        transition: 0.5s ease-in-out;
    }

    &.-fixedSidebar {
        padding-left: $sidebar-width;
    }

    &.-superAdmin {
        overflow-x: hidden;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        height: 100%;
        min-height: calc(100vh - 370px);
        margin: 0 auto;
        padding: 0 20px;
    }

    &__content {
        width: 100%;

        @include media-down(s) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__contentContainer {
        padding: 40px 36px 24px;
        background-color: $white;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);

        &.-tabs {
            padding: 0px 36px 24px;
        }

        &.-minHeight {
            min-height: 90vh;
        }
    }
}

.m-mainWrapper {
    display: flex;
}
