.m-languagePicker {
    position: relative;

    &__container {
        display: flex;
        align-items: center;
        margin-right: 20px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        cursor: pointer;
        text-transform: uppercase;

        .a-chevron {
            margin-left: 10px;
        }
    }

    &__popup {
        z-index: 2;
        opacity: 0;
        position: absolute;
        top: 30px;
        right: -22px;
        display: flex;
        flex-direction: column;
        width: max-content;
        min-width: 130px;
        transform: translateY(-20px);
        border: 1px solid rgba($light-blue, 0.2);
        border-radius: 10px;
        background-color: $white;
        padding: 4px;
        box-sizing: border-box;
        pointer-events: none;
        transition: 0.3s ease-in-out;

        &.-open {
            opacity: 1;
            transform: translateY(0);
            pointer-events: initial;
        }

        &::before {
            content: '';
            position: absolute;
            top: -5px;
            right: 40px;
            display: block;
            width: 7px;
            height: 7px;
            border: 1px solid rgba($light-blue, 0.2);
            border-right: 0;
            border-bottom: 0;
            background-color: $white;
            transform: rotate(45deg);
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            background-color: transparent;
            padding: 10px 8px 0;
            color: #383f52;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            &:hover {
                color: $dark-blue-20;
            }
        }

        .flagIcon {
            width: 25px;
            border-radius: 5px;
        }
    }
}
