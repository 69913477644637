.m-topJobPics {
    &__jobs {
        .-job {
            border-radius: 10px;
            border: 1px solid $dark-blue-20;
            padding: 20px;
            color: $dark-blue;
            transition: 0.3s ease-in-out;

            .-info {
                gap: 5px;
                word-break: break-word;
                max-width: 100%;

                span {
                    max-width: calc(100% - 25px);
                    word-break: break-word;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                svg {
                    min-width: 20px;
                }
            }

            &:hover {
                border-color: $dark-blue;
            }
        }

        .-notClickable {
            cursor: default;

            &:hover {
                border-color: $dark-blue-20;
            }
        }
    }
}
