.a-avatarIcon {
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border: 1px solid $dark-blue-20;
        border-radius: 100%;
        overflow: hidden;
    }
}
