.a-toggleSwitch {
    position: relative;
    display: inline-block;

    input {
        width: 0;
        height: 0;
        opacity: 0;
    }

    label {
        display: flex;
        align-items: center;
        width: fit-content;
    }

    &.-disabled {
        opacity: 0.7;

        .a-toggleSwitch__slider {
            cursor: default;
        }
    }

    &__label {
        margin-left: 15px;
    }

    &__slider {
        position: relative;
        width: 40px;
        height: 24px;
        border-radius: 20px;
        background-color: $dark-blue-20;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            left: 2px;
            bottom: 2px;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            background-color: white;
            transition: 0.3s ease-in-out;
        }
    }

    input:checked + .a-toggleSwitch__slider {
        background-color: $light-blue;
    }

    input:checked + .a-toggleSwitch__slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
        background-color: $white;
    }

    &.-withTooltip {
        cursor: pointer;

        .-tooltip {
            top: calc(100% + 5px);
            right: 10px;
            min-width: min-content;
            transform: translate(0, 0);

            span {
                white-space: nowrap;
            }
        }
    }

    &:hover {
        .-tooltip {
            opacity: 1;
            visibility: visible;
        }
    }
}
