.a-access {
    position: relative;

    &__select {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 5px 45px 5px 10px;
        border-radius: 5px;
        border: 1px solid $dark-blue-20;
        white-space: nowrap;

        svg {
            min-width: 16px;
        }

        .m-selectGroup__arrow {
            right: 10px;
        }
    }

    .m-dropdown {
        .m-radioGroupContainer {
            width: 100%;
            margin-bottom: 0;

            &__options {
                margin-top: 0;
                flex-direction: column;
                width: 100%;
                gap: 0;

                .-radioGroupElements {
                    width: 100%;

                    label {
                        width: 100%;
                    }

                    &>div {
                        width: 100%;
                        padding: 5px 10px;

                        label {
                            margin-right: 0;
                        }

                        .radio {
                            margin-right: 10px;
                        }
                    }

                }
            }
        }

    }
}