.m-notification {
    position: relative;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    gap: 10px;
    padding: 20px 30px;
    border-left: 3px solid $light-blue;
    background-color: $white;
    color: $dark-blue;

    @include media-down(m) {
        padding: 15px;
    }

    .removeItemIcon {
        z-index: 2;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 14px;
        height: 14px;

        &::after,
        &::before {
            height: 1.5px;
            background-color: $dark-blue-40;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;

        .-title {
            color: $light-blue;
            margin-right: 20px;
        }

        .a-btn {
            height: 21px !important;
            width: max-content !important;

            & > div {
                justify-content: flex-start !important;
                width: max-content;
            }
        }
    }

    &.-danger {
        border-left-color: $red;

        .-title {
            color: $red;
        }
    }

    &.-warning {
        border-left-color: $orange;

        .-title {
            color: $orange;
        }
    }
}
