.m-coverImage {
    position: relative;
    width: 100%;
    min-width: 100%;
    height: 280px;
    border-radius: 10px;
    background: linear-gradient(to right, $dark-blue, $light-blue);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-down(l) {
        height: 200px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 50%,
            rgba(23, 30, 60, 0.15) 120%
        );
    }

    .-button {
        width: 200px;
        margin-right: 20px;

        @include media-down(xl) {
            margin-bottom: 20px;
        }

        .a-btn {
            width: 100%;
        }
    }
}

.-coverImageBox {
    position: relative;

    .-cover_button {
        z-index: 2;
        position: absolute;
        right: 15px;
        top: 15px;

        svg {
            margin-right: 0 !important;
        }
    }
}
