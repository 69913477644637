.m-emailLink {
    display: flex;
    background: linear-gradient(180deg, $light-blue -91.44%, $dark-blue 44.26%);

    &__background {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: url(../../../images/sndImage.png);
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        padding: 100px 10px;

        @include media-down(m) {
            padding: 0;
        }
    }

    &__logo {
        width: 100px;
        height: 100px;
        background-image: url(../../../images/logo.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;

        @include media-down(m) {
            width: 150px;
            height: 45px;
            background-image: url(../../../images/logo-mobile.svg);
            margin: 50px 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px;
        background-color: $white;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .a-btn {
            width: 200px;
            min-width: 200px;
        }

        @include media-down(m) {
            width: 100%;
            height: calc(100vh - 145px);
            border-radius: 20px 20px 0px 0px;
            position: relative;
            justify-content: center;
            top: 0;
            left: 0;
            transform: none;

            .a-btn {
                width: 100%;
                min-width: 100%;
            }
        }
    }

    &__text {
        max-width: 455px;
    }
}
