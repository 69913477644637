.a-bodyTextRegular,
.a-bodyTextBold,
.a-bodyTextMedium {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.a-bodyTextMedium {
    font-weight: 600; //NOTE: it's 500 per Figma but it doesn't seem bold on web so it is changed here to 600
}

.a-bodyTextBold {
    font-weight: 600; //NOTE: it's 500 per Figma but it doesn't seem bold on web so it is changed here to 600
}

.a-mediumText,
.a-mediumTextSemiBold {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.a-mediumTextSemiBold {
    font-weight: 600;
}

.a-captionsTextRegular,
.a-captionsTextBold,
.a-captionsTextSemiBold {
    font-size: 12px;
    line-height: 18px; //NOTE: it is stated in the figma styleguide text that this is 15px but 18px is in use
    font-weight: 400;

    &.-separatorText {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 30px 0;

        .-line {
            position: absolute;
            width: 100%;
            height: 1px;
            border-top: 1px dashed $dark-blue-20;
        }

        .-text {
            z-index: 1;
            background-color: $white;
            padding: 0 20px;
        }
    }

    &.-pointer {
        cursor: pointer;
    }

    &.-disabled {
        opacity: 0.7;
        cursor: default;
    }
}

.a-captionsTextBold,
.a-captionsTextSemiBold {
    font-weight: 600;
}

.a-lightText {
    color: $dark-blue-80;

    &.-opacity-60 {
        color: $dark-blue-60;
    }

    &.-opacity-40 {
        color: $dark-blue-40;
    }
}

.a-whiteText {
    color: $white;

    &.-withLink:hover {
        color: $dark-blue-20;
    }
}

.a-blueLink {
    color: $light-blue;
    cursor: pointer;
}

.a-status {
    border-radius: 4px;
    padding: 3px 7px;
    white-space: nowrap;
    height: fit-content;
    border: 1px solid transparent;

    &.status-actions-trigger span {
        margin-right: 5px;
    }
}

.-green {
    border-color: $green;
    color: $green;
}

.-red {
    border-color: $red;
    color: $red;
}

.-redText {
    color: $red;
}

.-orangeText {
    color: $orange;
}

.-purpleText {
    color: $purple;
}

.-blueText {
    color: $light-blue;
}

.-greenText {
    color: $green;
}

.-orange {
    border-color: $orange;
    color: $orange;
}

.-purple {
    border-color: $purple;
    color: $purple;
}

.-blue {
    border-color: $light-blue;
    color: $light-blue;
}

.-gray {
    border-color: $dark-blue;
    color: $dark-blue;
}

.-pink {
    border-color: $pink;
    color: $pink;
}

.-yellow {
    border-color: $yellow;
    color: $yellow;
}

.-darkYellow {
    border-color: $dark-yellow;
    color: $dark-yellow;
}

.-grayLight {
    border-color: $dark-blue-20;
    color: $dark-blue;
}

.-orangeWithBcg {
    background-color: $orange-10;
    color: $orange;
    border: none;
}

.-purpleWithBcg {
    background-color: $purple-10;
    color: $purple;
    border: none;
}

.-blueWithBcg {
    background-color: $light-blue-10;
    color: $light-blue;
    border: none;
}

.-greenWithBcg {
    background-color: $green-10;
    color: $green;
    border: none;
}

.-silverWithBcg {
    background-color: $silver;
    color: $dark-gray;
    border: none;
}

.-grayWithBcg {
    background-color: $dark-blue-05;
    color: $dark-blue;
    border: none;

    &.-clients {
        padding: 5px 10px;
    }
}

.-silver {
    background-color: $silver;
    color: $dark-blue;
}

.a-headerLink {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    margin-left: 15px;
    color: $dark-blue;
    white-space: nowrap;
    transition: 0.3s ease-in-out;

    &:hover {
        color: $light-blue;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 3px;
        background-color: $light-blue;
        transition: 0.3s ease-in-out;
    }

    &.-active {
        color: $light-blue;

        &::after {
            width: 100%;
        }
    }
}

.a-centeredText {
    text-align: center;
}

.a-justifiedText {
    text-align: justify;
}

.errorMessage {
    display: block;
    margin-top: 3px;
    color: $red;
    font-size: 13px;
    letter-spacing: -0.2px;
    line-height: 16px;
    opacity: 0;
    transition: 0.3s ease-in-out;

    &.-active {
        opacity: 1;
    }

    &.-boxError {
        text-align: center;
        border-radius: 10px;
        padding: 15px;
        background-color: $red-10;
    }
}

.a-positionFilled,
.a-approved,
.a-declined {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 21px;
}

.a-positionFilled {
    color: $dark-blue-50;
}

.a-approved {
    color: $green;
}

.a-declined {
    color: $red;
}

.a-allCaps {
    text-transform: uppercase;
}

.a-link {
    color: $dark-blue;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        color: $dark-blue-60;
    }
}

.a-capitalize {
    text-transform: capitalize;
}

.a-darkBlueText {
    color: $dark-blue !important;
}

.a-darkGrayText {
    color: $dark-gray;
}

.a-boldText {
    font-weight: 600;
}

.-liDisc {
    list-style-type: disc;
    position: relative;
    margin-left: 20px;

    &.-withBiggerLeftSpace {
        margin-left: 40px;
    }
}

.-liNumber {
    list-style-type: auto;
    position: relative;
    margin-left: 20px;

    &.-noSpaceLeft {
        margin-left: 0;
        list-style-position: inside;
    }
}
