.a-blurredContainer {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.5) 9.33%,
            #ffffff 86.83%
        );
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__withoutBackground {
        filter: blur(2px);

        .a-btn {
            pointer-events: none;
        }
    }

    .a-stepCard,
    .-job {
        filter: blur(2px);
    }

    .overlayContainer {
        position: absolute;
        width: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}
