.m-columnTabs {
    &__tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        color: $dark-blue-80;
        border: 1px solid transparent;
        background: transparent;
        transition: 0.3s ease-in-out;

        &.-withSubItems {
            padding: 0;

            .a-accordion {
                padding-left: 10px;
                padding-right: 10px;

                &__header {
                    padding: 10px 0;
                }

                &__content {
                    margin-top: 0;
                    margin-left: -12px;
                    margin-right: -12px;
                }
            }
        }

        &:not(.-withSubItems).-active {
            border: 1px solid $light-blue;
            background: $light-blue-05;
        }

        &:hover {
            background: $light-blue-05;
        }

        &.-withSubItems:has(.a-accordion.-open) {
            background-color: $white;
        }

        .-tabName {
            svg {
                margin-right: 10px;
            }
        }

        .-incomplete {
            width: 6px;
            height: 6px;
            min-width: 6px;
            min-height: 6px;
            margin-left: 5px;
            border-radius: 100%;
            background-color: $red;
        }
    }
}
