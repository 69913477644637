.a-inputRange {
    input[type='range'] {
        width: 100%;
        height: 3px;
        appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
    }

    input[type='range']:focus {
        outline: none;
    }

    input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        background: $dark-blue-20;
        border-radius: 1px;
        border: 0px solid #000000;
    }
    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid $dark-blue-20;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        background-color: $white;
        cursor: pointer;
        margin-top: -15px;
    }

    input[type='range']::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        background: $dark-blue-20;
        border-radius: 1px;
        border: 0px solid #000000;
    }

    input[type='range']::-moz-range-thumb {
        border: 1px solid $dark-blue-20;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        background-color: $white;
        cursor: pointer;
    }

    input[type='range']::-ms-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    input[type='range']::-ms-fill-lower {
        background: $dark-blue-20;
        border: 0px solid $dark-blue-20;
        border-radius: 2px;
    }

    input[type='range']::-ms-fill-upper {
        background: $dark-blue-20;
        border: 0px solid $dark-blue-20;
        border-radius: 2px;
    }

    input[type='range']::-ms-thumb {
        margin-top: -15px;
        border: 1px solid $dark-blue-20;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        background-color: $white;
        cursor: pointer;
    }
}
