.a-skillsList {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;

    .-value {
        border: 1px solid;
        border-radius: 4px;
        margin: 2px;
        padding: 3px 7px;
    }
}
