.m-searchField {
    position: relative;
    min-width: 310px;

    @media only screen and (min-width: 1250px) {
        min-width: 310px;
    }

    @include media-down(m) {
        width: 100%;
        min-width: unset;
    }

    .m-inputGroup {
        margin-bottom: 0;

        .a-input {
            margin-top: 0;
            height: 36px;

            &.-mainSearch {
                padding: 7px 32px 7px 10px;
            }
        }

        svg {
            right: 15px;
        }
    }

    @include media-down(m) {
        .m-dropdown {
            position: fixed;
            top: 60px;
            right: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            min-height: 100vh;
        }
    }
}
