.a-banner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-image: url('../../../images/homepage-bg.png');
    background-position: center;
    background-size: cover;
    background-color: $dark-blue;
    padding: 25px;

    @include media-down(m) {
        background-image: url('../../../images/homepage-bg-small.png');
        padding-right: 50px;
    }

    &__image {
        position: relative;
        background-color: $dark-blue-20;
        width: 152px;
        height: 152px;
        min-width: 152px;
        min-height: 152px;
        margin-right: 50px;
        margin-left: 15%;
        border-radius: 50%;
        overflow: hidden;

        @include media-down(m) {
            width: 95px;
            height: 95px;
            min-width: 95px;
            min-height: 95px;
            margin-left: 30px;
            margin-right: 20px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .-placeholder,
        .-image {
            position: absolute;
        }

        .-placeholder {
            z-index: 1;
        }

        .-image {
            z-index: 2;
            background-color: $white;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .-title,
        .-name,
        .-subtitle {
            color: $white;
        }

        .-title {
            max-width: 500px;
            overflow: hidden;
            text-overflow: ellipsis;

            @include media-down(m) {
                font-size: 20px;
                line-height: normal;
            }
        }
    }

    &.-project {
        position: relative;
        justify-content: flex-start;
        background-image: url('../../../images/project-bg-small.png');

        @include media-up(m) {
            justify-content: space-between;
            background-image: url('../../../images/project-bg.png');
            padding-left: 40px;
            padding-right: 100px;
        }

        .a-banner__image {
            margin-left: 0;
        }

        .a-banner__text {
            @include media-up(m) {
                order: -1;
            }
        }

        .a-banner__share {
            position: absolute;
            top: 20px;
            right: 20px;

            .-iconWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                border: 2px solid $dark-blue-20;
                background-color: $white;
                cursor: pointer;

                @include media-down(m) {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}
