.m-userMainInfo {
    position: relative;

    &__header {
        .a-status {
            position: absolute;
            top: -15px;
            left: -25px;

            @include media-down(m) {
                top: 0;
                left: 0;
            }
        }

        .-editProfileButton {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @include media-down(m) {
                top: 0;
                right: 0;
            }
        }
    }

    &__mainInfo {
        display: flex;
        flex-direction: column;

        &--image,
        &--placeholder {
            background-color: $dark-blue-20;
            border: 0.5px solid $dark-blue-20;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            min-width: 50%;
            height: 0;
            padding-top: 50%;
            margin: 0 auto;
            border-radius: 100%;

            @include media-down(l) {
                width: 40%;
                min-width: 40%;
                padding-top: 40%;
            }

            .-edit {
                position: absolute;
                top: unset;
                bottom: 5px;
                right: 5px;
                background: none;
                outline: inherit;
                border: none;
                padding: 0;
                cursor: pointer;
                transition: 0.3s ease-in-out;
            }

            .-placeholderImage {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &--image {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            transition: 0.3s ease-in-out;
        }

        &--info,
        &--contactInfo {
            display: flex;
            flex-direction: column;
        }

        &--info {
            align-items: center;

            .listOfStatuses {
                justify-content: center;
            }
        }

        &--contactInfo {
            width: 100%;

            .-name {
                word-break: break-word;
            }

            .-contactInfo {
                flex-wrap: wrap;
                gap: 10px;
            }

            .-website a,
            .-email span,
            .-email a,
            .-phone a {
                width: 100%;
                word-break: break-word;

                @include media-up(s) {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: normal;
                }
            }
        }
    }

    &__about {
        .-aboutMaininfo {
            display: flex;

            .column {
                width: 50%;

                span.-opacity-60 {
                    @include media-up(xl) {
                        white-space: nowrap;
                    }
                }

                &:first-of-type {
                    position: relative;
                    padding-right: 30px;
                    margin-right: 30px;

                    @include media-down(s) {
                        padding-right: 15px;
                        margin-right: 15px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        width: 1px;
                        border-right: 1px solid $dark-blue-20;
                    }
                }

                svg {
                    min-width: 21px;
                    margin-right: 5px;
                }
            }

            &.-noNationality {
                .column:first-of-type {
                    &::after {
                        border: none;
                    }
                }
            }
        }

        .-clientsBcg {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            background-color: $dark-blue-15;
            border-radius: 100%;
        }

        .-about {
            word-break: break-word;
        }

        .-rowInformation {
            flex-wrap: wrap;
            gap: 20px;

            .-rowElement {
                .a-status {
                    margin-left: 5px;
                }
            }

            @include media-down(m) {
                flex-direction: column;
                align-items: flex-start !important;
                gap: 15px;
            }
        }

        .-phoneNumberWithTooltip,
        .-tinVatWithTooltip {
            position: relative;
            cursor: pointer;

            .-tooltip {
                right: 0;
                white-space: nowrap;
                min-width: unset;
                transform: translate(20px, 0);
            }

            &:hover {
                .-tooltip {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .-tinVatWithTooltip {
            .-tooltip {
                @include media-down(m) {
                    left: 0;
                    right: unset;
                    transform: translate(0, 0);
                }

                @include media-down(xs) {
                    white-space: wrap;
                    min-width: 200px;
                }
            }
        }
    }

    .a-progressBar {
        align-items: flex-start;
        padding-left: 0;
        padding-right: 0;

        .-bar {
            margin: 5px 0px 0px;
        }
    }

    .-badges {
        gap: 15px;
        flex-wrap: wrap;

        @include media-down(m) {
            gap: 10px;
        }
    }

    .-editProfileButton {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include media-down(m) {
            top: 0;
            right: 0;
        }
    }

    .-levels {
        white-space: nowrap;
    }
}
