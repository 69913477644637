.m-addAndUpload {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-height: max-content;

    label {
        max-height: max-content;
    }

    &__title {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-bottom: 20px;

        .-subtitle {
            margin-left: 10px;
        }

        @include media-down(m) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .-subtitle {
                margin-left: 0;
                margin-top: 5px;
            }
        }
    }

    &__box {
        display: flex;
        max-height: max-content;

        & > div,
        .-attachmentNote {
            max-height: max-content;
        }

        @include media-down(l) {
            flex-direction: column;
        }
    }

    .a-file {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-height: 70px;
        border: 1px dashed $light-blue;
        border-radius: 10px;
        background-color: $light-blue-05;
        padding: 15px;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $light-blue;
            border-radius: 6px;
            padding: 9px;
            transition: 0.3s ease-in-out;
        }

        p {
            margin-left: 15px;
            transition: 0.3s ease-in-out;
        }

        .imageContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 60px;
            margin: 0 10px;

            .image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 50%;
            }
        }

        &__multiple {
            &--remove {
                cursor: pointer;
            }
        }
    }

    &.-disabled {
        .a-file {
            border-color: $dark-blue-40;
            background-color: $dark-blue-05;
            cursor: default;

            .icon {
                border-color: $dark-blue-20;

                svg path {
                    stroke: $dark-blue-20;
                }
            }

            .-descriptionText,
            .a-mediumText {
                color: $dark-blue-40;
            }
        }

        .removeItemIcon {
            cursor: default;
        }
    }

    .files {
        padding: 0 20px;

        @include media-down(l) {
            width: 100%;
            margin-top: 20px;
        }

        .file {
            display: flex;
            min-width: 20px;
            min-height: 20px;

            .-fileName {
                margin: 0 10px;
                word-break: break-word;
            }

            svg {
                min-width: 20px;
            }
        }
    }

    .m-avatar {
        &__popup {
            top: unset;
            right: 0;
            bottom: 0;
            transform: translate(calc(100% - 38px), 100%);

            .-disabled {
                opacity: 0.5;
            }
        }

        &__item {
            cursor: pointer;
        }
    }
}
