//primary colors
$dark-blue: #171e3c;
$dark-blue-80: rgba($dark-blue, 0.8);
$dark-blue-70: rgba($dark-blue, 0.7);
$dark-blue-60: rgba($dark-blue, 0.6);
$dark-blue-50: rgba($dark-blue, 0.5);
$dark-blue-40: rgba($dark-blue, 0.4);
$dark-blue-30: rgba($dark-blue, 0.3);
$dark-blue-20: rgba($dark-blue, 0.2);
$dark-blue-10: rgba($dark-blue, 0.1);
$dark-blue-15: rgba($dark-blue, 0.15);
$dark-blue-05: rgba($dark-blue, 0.05);
$dark-blue-03: rgba($dark-blue, 0.03);
$dark-blue-02: rgba($dark-blue, 0.02);

$white: #fff !default;

$light-blue: #0177a9;
$light-blue-80: rgba($light-blue, 0.8);
$light-blue-60: rgba($light-blue, 0.6);
$light-blue-50: rgba($light-blue, 0.5);
$light-blue-40: rgba($light-blue, 0.4);
$light-blue-20: rgba($light-blue, 0.2);
$light-blue-15: rgba($light-blue, 0.15);
$light-blue-10: rgba($light-blue, 0.1);
$light-blue-30: rgba($light-blue, 0.3);
$light-blue-05: rgba($light-blue, 0.05);
$light-blue-02: rgba($light-blue, 0.02);
$light-blue-30: rgba($light-blue, 0.3);
$light-blue-03: rgba($light-blue, 0.03);

//secondary colors

$green: #008a5c;
$green-80: rgba($green, 0.8);
$green-60: rgba($green, 0.6);
$green-30: rgba($green, 0.3);
$green-10: rgba($green, 0.1);

$orange: #fb5607;
$orange-80: rgba($orange, 0.8);
$orange-60: rgba($orange, 0.6);
$orange-30: rgba($orange, 0.3);
$orange-10: rgba($orange, 0.1);

$red: #e50004;
$red-80: rgba($red, 0.8);
$red-60: rgba($red, 0.6);
$red-30: rgba($red, 0.3);
$red-10: rgba($red, 0.1);

$purple: #692c93;
$purple-80: rgba($purple, 0.8);
$purple-60: rgba($purple, 0.6);
$purple-30: rgba($purple, 0.3);
$purple-10: rgba($purple, 0.1);

$pink: #df006f;
$yellow: #e49d22;
$dark-yellow: #b67600;

$black: #000 !default;

$silver: #f3f4f5;

$ligth-gray: #ababab;

// Set a specific jump point for requesting color jumps
$color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$contrast-threshold: 150 !default;

$dark-gray: #454b63;
