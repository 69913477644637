.a-badgeTooltipInfo {
    position: relative;
    cursor: pointer;
    display: flex;
    height: max-content;
    width: max-content;

    .tooltip-content {
        display: none;
        position: absolute;
        background-color: $white;
        color: #171e3c;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        max-width: 300px;
        width: max-content;
        white-space: normal;
        overflow-wrap: break-word;
        z-index: 1;
        pointer-events: auto;

        &.visible {
            visibility: hidden !important;
        }

        &::before {
            content: '';
            position: absolute;
            border: 10px solid transparent;
            z-index: 2;
            visibility: hidden;
        }

        &::after {
            display: none;
        }

        &.-left {
            .-badge {
                left: unset !important;
                right: calc(100% - 30px);
            }
        }
    }

    &:hover .tooltip-content,
    &:focus-within .tooltip-content {
        display: flex;
        opacity: 1;
        visibility: visible;
        max-height: 163px;
        max-width: 400px;

        div {
            svg {
                overflow: unset;
                margin-right: 15px;
            }
        }
    }
}

// Overflow text
.overflowText {
    display: -webkit-box;
    -webkit-line-clamp: 3; // Number of lines to show
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
