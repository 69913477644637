.m-tableCards {
    margin: 25px 0;

    @include media-down(m) {
        margin: 15px 0;
    }

    .m-accordion {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $dark-blue-20;
        padding: 10px 0;
        transition: 0.1s ease-in-out;

        .-wrapper {
            display: flex;
            justify-content: space-between;

            .-titleItem {
                white-space: nowrap;
            }

            .a-actions__buttons {
                button:disabled {
                    svg {
                        cursor: not-allowed;
                    }
                }
            }

            .a-btn.-onlyIcon {
                padding: 0;
            }
        }

        &__card {
            display: flex;
            width: 100%;

            .chevronIcon {
                svg {
                    margin-right: 10px;
                    transition: 0.3s ease-in-out;
                    cursor: pointer;
                }
            }

            &.-open {
                .chevronIcon {
                    svg {
                        transform: rotate(-180deg);
                    }
                }
            }

            &--header {
                display: flex;
                align-items: baseline;
                gap: 10px;
                transition: 0.3s ease-in-out;

                .-title,
                .-cellItem {
                    word-break: break-word;
                }
            }
        }

        &__content {
            max-height: var(--content-max-height);
            margin-left: 35px;
            overflow: hidden;
            transition: 0.3s ease-in-out;

            &.-visible {
                --content-max-height: 100%;

                &:has(.m-dropdown.-open) {
                    overflow: visible;
                }
            }

            .m-dropdown {
                height: fit-content;
            }

            .-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                .-accessControlActions {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                }
            }

            &--elements {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }

        .a-actions {
            margin-left: 15px;

            & > svg {
                padding-right: 5px;
            }
        }
    }
}
