.m-table {
    position: relative;
    table-layout: fixed;
    border-radius: 10px;
    background-color: $white;
    padding-bottom: 40px;

    table {
        height: 1px; //this is where magic happens
    }

    ::-webkit-scrollbar {
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        opacity: 0.5;
        border-radius: 4px;
        background-color: $dark-blue-20;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: $dark-blue-20;
    }

    &__container {
        display: block;
        width: 100%;
        overflow-x: visible;

        .-sorting {
            display: flex;
            align-items: center;
            white-space: nowrap;

            svg {
                margin-right: 5px;
            }
        }
    }

    table {
        width: 99.9%;
        max-width: 99.9%;
        margin: 0 auto;
    }

    thead {
        font-size: 12px;
        line-height: 18px;

        th {
            border: none;
            border-bottom: none;
            background-color: $light-blue-05;
            padding: 8px 15px;

            div {
                display: flex;
                align-items: center;
                font-weight: 400 !important;

                span {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    tbody {
        td {
            border-bottom: 1px solid $dark-blue-20;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.15px;
            max-width: 250px;
            word-break: break-word;
            vertical-align: top;

            &:last-child {
                min-width: 50px;
            }

            @include media-down(xs) {
                padding: 10px;
                font-size: 12px;
            }

            div {
                display: flex;
                align-items: center;
            }

            .a-status {
                width: max-content;
            }

            span {
                word-break: break-word;
            }

            .a-link {
                word-break: break-word;
            }

            .-cellWrapper {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 10px;
            }
        }

        td:first-child {
            @include media-landscape() {
                width: 77px !important;
                padding: 0;
            }

            @include media-down(xs) {
                width: 77px;
                padding: 0;
            }
        }

        .m-table__empty {
            text-align: center;

            &:hover {
                background-color: $white;
                cursor: default;
            }
        }

        tr {
            vertical-align: baseline;
            background-color: $white;
            transition: 0.3s ease-in-out;

            &:hover {
                cursor: default;
                background-color: $light-blue-03;
            }

            &.-selected {
                background-color: $light-blue-03;

                &:hover {
                    cursor: pointer;
                    background-color: $light-blue-03 !important;
                }
            }
        }

        tr.clickable {
            &:hover {
                cursor: pointer;
                background-color: $light-blue-03;
            }
        }

        .m-table__placeholder {
            td {
                vertical-align: middle;
                height: 60px;

                span {
                    display: block;
                    width: 100%;
                    height: 16px;
                    border-radius: 50px;
                    background-color: $dark-blue-05;
                }

                .-cellWrapper {
                    justify-content: flex-end;
                }

                .-placeholderActions {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                    }
                }
            }
        }

        tr:nth-child(odd).m-table__placeholder {
            td {
                span {
                    background-color: $dark-blue-05;
                }
            }
        }

        tr:nth-child(even).m-table__placeholder {
            td {
                span {
                    background-color: $dark-blue-05;
                }
            }
        }

        .m-table__actionsCell {
            justify-content: flex-end;

            .-accessControlActions {
                gap: 5px;
                justify-content: flex-end;
                flex-wrap: wrap;

                &.-disabled {
                    .a-access__select,
                    .m-selectGroup__arrow {
                        opacity: 0.7;
                        cursor: default;
                    }
                }

                @include media-down(l) {
                    flex-wrap: wrap;
                }
            }
        }
    }

    &__actions {
        display: flex;
        align-items: flex-start;

        span {
            cursor: pointer;

            @include media-up(m) {
                white-space: nowrap;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        margin-bottom: 20px;

        .-title {
            white-space: nowrap;
        }

        &.-fullWidthTitle {
            flex-direction: column;

            .-title,
            .m-table__actions {
                width: 100%;
            }

            .m-table__actions .-wrapper {
                width: 100%;
            }
        }

        .m-table__actions {
            .-wrapper {
                display: flex;
                align-items: center;
                width: 100%;

                .-tableSearch {
                    width: 100%;
                }
            }

            .a-btn {
                height: 50px;
            }

            .-actionsWrapper {
                display: flex;
                width: 100%;

                div {
                    width: 100%;
                }
            }

            &.-actionButtons {
                max-width: fit-content;
            }
        }

        @include media-down(m) {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }
    }

    &__rowActions {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        max-height: 0px;
        border-radius: 6px;
        background-color: transparent;
        padding: 0 10px;
        transition: 0.3s ease-in-out;

        &.-show {
            opacity: 1;
            min-height: 100%;
            max-height: 1000px;
            background-color: $dark-blue-05;
            transition: 0.3s ease-in-out;

            .m-table__rowActionsTitle {
                opacity: 1;
            }
        }

        .m-table__rowActionsTitle {
            opacity: 0;
            display: inline-block;
            font-size: 14px;
            transition: 0.3s ease-in-out;
        }

        .m-table__actions {
            flex-wrap: wrap;

            .a-btn {
                margin: 3px;
            }
        }
    }

    &__actionsContainer {
        position: relative;
        min-height: 50px;
        margin-bottom: 10px;
        padding: 0 40px;
    }

    &__placeholder {
        &.-mobile {
            height: 35px;
            margin: 10px 25px;
            background: $dark-blue-05;
            border-radius: 20px;

            @include media-down(s) {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
    }

    @include media-down(xl) {
        .-emailCell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    @include media-down(m) {
        .-emailCell {
            white-space: wrap;
            max-width: unset;

            span {
                white-space: wrap;
            }
        }
    }

    .-cellIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        border-radius: 4px;
        background-color: $light-blue-05;
        padding: 2px;

        svg {
            max-height: 16px;
            max-width: 20px;
        }

        @include media-down(xl) {
            display: none;
        }
    }

    .-fileCell {
        svg {
            min-width: 20px;
        }

        .-cellValue {
            word-break: break-all;
        }
    }
}
