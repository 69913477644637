.m-diveRecord {
    &__list {
        .a-stepCard__header--left {
            .-textContent {
                flex-wrap: nowrap;

                .-title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    word-break: break-word;
                    white-space: wrap;

                    @include media-down(m) {
                        -webkit-line-clamp: 2;
                    }
                }
            }
        }

        .divingModeAndRole {
            .-infoRow {
                &:last-child {
                    padding-bottom: 5px;
                }

                @include media-down(m) {
                    flex-direction: row;
                    gap: 5px;
                    padding: 5px 0;

                    .-info {
                        padding: 0;
                    }
                }
            }
        }
    }
}
