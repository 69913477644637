.m-howTo {
    &__iframe {
        position: relative;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    img {
        border: 1px solid $dark-blue-05;
    }
}
