@mixin scrollbar($width: 6px, $thumbBackground: $dark-blue-20) {
    &::-webkit-scrollbar {
        margin: 5px 0;
        width: $width;
        height: $width;
    }

    &::-webkit-scrollbar-track {
        background: $white;
        margin-top: 5px;
        margin-bottom: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumbBackground;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: darken($thumbBackground, 5%);
    }
}
