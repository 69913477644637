.m-completeProfile {
    position: absolute;
    width: calc(100% - 15px);
    gap: 30px;
    border-radius: 10px;
    border: 1px solid $dark-blue-20;
    padding: 20px 30px 20px 20px;
    opacity: 0;
    visibility: hidden;
    transform: translateX(+40%);
    transition: .3s ease-in-out;

    @include media-down(l) {
        position: relative;
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }

    svg {
        min-width: 25px;
    }

    @include media-down(s) {
        gap: 10px;
    }

    &.-diver {
        padding-right: 30px;

        @include media-down(s) {
            flex-direction: column;
        }
    }

    &__label {
        svg {
            margin-right: 10px;
            min-width: 20px;
        }
    }

    &__next {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        background-color: $silver;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        cursor: pointer;

        svg path {
            transition: .3s ease-in-out;
        }

        &:hover {
            svg path {
                stroke: $light-blue;
            }
        }

        @include media-down(l) {
            display: none;
        }
    }

    &.-active {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }
}

.-slider {
    position: relative;
    width: 100%;
    margin-right: 15px;
    overflow: hidden;

    &.-active {
        min-height: 115px;

        @include media-down(xl) {
            min-height: 150px;
        }

        @include media-down(s) {
            min-height: 140px;

            .a-btn {
                width: 100%;
            }
        }
    }

    @include media-down(l) {
        display: flex;
        gap: 10px;
        min-height: unset;
        overflow: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        cursor: pointer;

        &::-webkit-scrollbar {
            display: none;
        }

        .m-completeProfile {
            min-width: 70%;

            @include media-down(xs) {
                min-width: 85%;
            }
        }
    }

    &.-noMargin {
        margin-right: 0;

        .m-completeProfile {
            width: 100%;
            min-width: 100%;
        }
    }
}