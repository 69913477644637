.m-footer {
    background-color: $light-blue;
    padding-top: 15px;
    padding-bottom: 15px;
    color: $white;

    a {
        color: $white;
    }

    &__policy {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;

        &--links {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            a {
                position: relative;
                transition: 0.3s ease-in-out;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    scale: 0;
                    height: 1px;
                    width: 100%;
                    background-color: $white;
                    transition: 0.3s ease-in-out;
                }

                &:hover {
                    color: $white;

                    &::after {
                        scale: 1;
                    }
                }
            }
        }
    }
}
