.a-dataGrid {
    display: flex;
    align-items: center;
    justify-content: center;

    &__cell {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .a-mediumText {
            white-space: nowrap;
        }

        &.-borderBottom {
            border-bottom: 1px dashed $dark-blue-20;
            padding-bottom: 15px;
        }

        &:not(.-borderBottom) {
            padding-top: 15px;
        }
    }

    &>div {
        width: 100%;
    }

    &>div:first-of-type {
        border-right: 1px dashed $dark-blue-20;

        div {
            padding-right: 15px;
        }
    }

    &>div:last-of-type {
        div {
            padding-left: 15px;
        }
    }
}