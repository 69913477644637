.m-boxes {
    display: flex;
    flex-wrap: wrap;

    @include media-down(l) {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    @include media-down(s) {
        padding: 0;
    }

    &.-left {
        .m-boxes__breadcrumbs {
            order: -1;
        }

        .m-boxes__side {
            order: 1;
            margin-left: 0;
            margin-right: 20px;
        }

        .m-boxes__main {
            order: 2;
            min-height: 75vh;
            height: 100%;

            form {
                flex: 1;
                width: 100%;
            }

            .-content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        .m-boxes__top {
            order: 0;
        }
    }

    &.-settings {
        @include media-down(xl) {
            flex-direction: column;
            flex-wrap: nowrap;

            .m-boxes__main {
                width: 100%;
            }

            .m-boxes__side {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: unset;
                margin-left: 0;
            }
        }
    }

    &.-fullHeight {
        min-height: calc(100vh - 110px);
    }

    &__top {
        &.-coverImage {
            display: flex;
            justify-content: end;
            align-items: flex-end;
            margin-top: 100px;
        }
    }

    &__top,
    &__main,
    &__side,
    &__white {
        position: relative;
        border-radius: 10px;
        padding: 30px;

        & > .removeItemIcon.-large {
            position: absolute;
            right: 20px;
            top: 20px;
        }

        @include media-down(l) {
            padding: 15px !important;
        }
    }

    &__top,
    &__breadcrumbs {
        width: 100%;
        min-width: 100%;
        margin-bottom: 20px;

        &.-sectionOrderSmallDevices {
            @include media-down(l) {
                order: -2;
            }
        }
    }

    &__breadcrumbs {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    &__main {
        display: flex;
        flex-direction: column;
        width: calc(100% - 370px);
        background-color: $white;

        &.-fullWidth {
            width: 100%;
        }

        &.-fullHeight {
            @include media-up(l) {
                min-height: 100%;
                height: 100%;
            }
        }

        &.-projectDetails {
            .a-stepCard {
                border: none;
                border-radius: 0;
                padding: 0;

                &__header {
                    margin-bottom: 10px;

                    .a-bodyTextMedium {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                &__body {
                    .-info {
                        gap: 5px;
                        padding: 10px 0;
                    }
                }
            }

            .-info:last-of-type {
                border-bottom: none;
            }
        }

        &.-table {
            padding-bottom: 0;
        }

        @include media-down(l) {
            width: 100%;
            order: 0;
        }

        .a-stepCard {
            .-withButtonsWrapper {
                .a-btn {
                    width: unset;

                    @include media-down(m) {
                        margin-top: 10px;
                    }

                    @include media-down(s) {
                        height: 50px !important;
                    }

                    div {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        .-buttons {
            .-prevButton,
            .-nextButton {
                .a-btn {
                    width: 100%;
                }
            }

            @include media-down(m) {
                .-nextButton {
                    order: -1;
                    margin-top: 10px;
                }
            }
        }

        &.-noPadding {
            padding: 0 !important;
        }

        &.-noBackground {
            padding: 0 !important;
            background-color: transparent;
        }

        &.-diverHomepage {
            background-color: transparent;
            padding: 0;

            @include media-down(l) {
                padding: 0 !important;
            }
        }

        .-unitsAndFormats {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &.-withDefaultContent {
            .-defaultContent {
                margin: auto 0;

                .-defaultContentDescription {
                    max-width: 670px;

                    @include media-down(s) {
                        max-width: 100%;
                    }
                }
            }
        }

        @include media-up(l) {
            .-generalPreferences {
                .-buttons {
                    .-prevBtn {
                        padding-left: 0;
                    }

                    .-nextBtn {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    &__white {
        background-color: $white;
        border-radius: 10px;

        &.-noPadding {
            padding: 0;
        }

        &.-profilePage {
            padding-bottom: 15px;
            margin-bottom: 0;

            @include media-down(l) {
                padding: 0;
            }
        }
    }

    &__white,
    &__side {
        &.-smallerPadding {
            padding: 30px 20px;

            .-sidebarTitle,
            .-sidebarDescription {
                margin-left: 10px;
            }
        }
    }

    &__side {
        width: 100%;
        max-width: 350px;
        height: fit-content;
        margin-left: 20px;
        background-color: $white;

        &.-noBackground {
            padding: 0 !important;
            background-color: transparent;
        }

        .m-boxes__side {
            margin-left: 0;
        }

        .-diveRecordSideCard {
            .a-stepCard__footer .a-btn {
                @include media-down(l) {
                    width: unset;
                }

                @include media-down(xs) {
                    width: 100%;
                }
            }
        }

        .a-btn {
            width: 100%;
        }

        @include media-down(l) {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: unset !important;
            margin-left: 0;
        }

        &.-button {
            padding: 0 !important;
            background-color: transparent;

            .a-btn {
                width: 100%;
            }

            @include media-down(l) {
                order: 1;
            }
        }

        &.-buttonText {
            @include media-down(l) {
                order: 1;
                display: flex;
                flex-direction: column;
                margin-top: 0;
            }
        }

        &.-left {
            margin-left: 0;
            margin-right: 20px;

            @include media-down(m) {
                margin-top: 0;
            }

            &.-textContent {
                margin-left: 0;
            }
        }

        &.-smallSidebar {
            max-width: 350px;

            @include media-down(l) {
                margin-top: 0;
            }
        }

        &.-sectionOrderSmallDevices {
            @include media-down(l) {
                order: -1;
            }
        }
    }

    &.-singleProject {
        @include media-down(l) {
            .m-boxes__side.-button {
                order: 3;
            }
        }
    }

    &.-homepage {
        @include media-down(l) {
            .m-boxes__main {
                order: 2;
            }

            .-noBackground {
                order: 1;
            }

            .m-boxes__side.-button {
                order: 3;
            }
        }
    }

    .-createDiveRecord {
        .a-btn {
            width: 100%;
        }
    }

    .-box {
        padding: 30px;
        background-color: $white;
        border-radius: 10px;

        @include media-down(l) {
            padding: 15px;
        }
    }

    .-notificationSettings {
        max-width: 338px;
    }
}
