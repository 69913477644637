.m-borderCheckboxGroup {
    display: flex;
    flex-direction: column;
    max-width: 400px;

    &.-frame {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 20px 0;
        border: 1px solid rgba($dark-blue, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        box-shadow:
            0 0px 0px 0px rgba($dark-blue, 0.2),
            0 0px 2px 0 rgba($dark-blue, 0.2);

        .a-checkbox {
            width: 40%;
            margin-right: 20px;
        }

        .m-borderCheckboxGroup__label {
            position: absolute;
            top: -14px;
            left: 14px;
            background-color: $white;

            &.-required {
                &::after {
                    position: relative;
                    content: '*';
                    color: $red;
                    right: -5px;
                }
            }
        }

        .m-borderCheckboxGroup__options {
            padding: 0 20px;
        }
    }

    &.-error {
        border-color: $red;

        .errorMessage {
            margin-top: -7px;
        }
    }

    &__label {
        margin-bottom: 10px;
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        @include media-down(m) {
            flex-direction: column;
        }

        .a-checkbox {
            margin-bottom: 10px;
            margin-right: 20px;
            border: 1px solid $dark-blue-20;
            padding: 12px 12px 12px 15px;
            width: 100%;
            border-radius: 5px;

            label {
                &::after {
                    margin-left: 15px;
                }
            }

            @include media-down(xs) {
                width: auto;
            }
        }

        .a-btn {
            width: 100%;
        }
    }
}
