.m-multiselectGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;

    .-clearAllIcon svg {
        position: absolute;
        right: 40px;
        transform: translateY(-50%);
        transition: 0.3s ease-in-out;
        overflow: visible;
        top: 27px;
        cursor: pointer;
        z-index: 4;
    }

    .-clearAllIcon svg path {
        stroke: rgba(23, 30, 60, 0.6);
    }

    label {
        margin-bottom: 5px;
    }

    &.-disabled {
        opacity: 0.7;

        .removeItemIcon,
        .m-selectGroup__arrow svg {
            cursor: default;
        }
    }

    &__container {
        position: relative;
    }

    &__multiselect {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        outline: 0;
        padding: 0 40px 0 15px;
        transition: 0.3s ease-in-out;
        padding-right: 40px;
        filter: none;

        input {
            padding: 0 40px 0 15px;
        }

        &:invalid {
            box-shadow: none;
        }

        &:focus {
            ~ svg {
                stroke: $dark-blue-60;
            }
        }

        &.-open {
            outline: 0;

            & + span {
                transform: translateY(-50%) rotate(225deg);
            }
        }

        &.-error {
            border-color: $red;
        }

        &.-withClearAllIcon {
            .a-input {
                padding-right: 65px;
            }
        }
    }

    &__removeAll {
        position: absolute;
        top: 50%;
        right: 5px;
        width: 15px;
        height: 15px;
        display: flex;
        margin: 0 5px;
        transform: translateY(-50%);
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            width: 100%;
            height: 1px;
            background: $dark-blue-60;
        }

        &::before {
            transform: translateY(-50%) rotate(-45deg);
        }

        &::after {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;

        .-listingTitle {
            width: 100%;
        }

        .-selectedSkills {
            &:not(:last-of-type) {
                border-bottom: 1px dashed $dark-blue-20;
            }

            .-skills {
                flex-wrap: wrap;
            }
        }
    }

    &__placeholder {
        color: $dark-blue-60;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
        margin-bottom: 5px;
        border-radius: 15px;
        background: $dark-blue-05;
        padding: 5px 8px;
        color: $dark-blue;
        word-break: break-word;
        overflow: hidden;
        animation-name: show;
        animation-duration: 0.3s;

        .label {
            margin-right: 5px;
            line-height: 16px;
        }

        @keyframes show {
            from {
                transform: scale(0);
            }

            to {
                transform: scale(1);
            }
        }

        .removeItemIcon {
            position: relative;
            top: unset;
            right: unset;
            width: 9px;
            height: 9px;
            min-width: 9px;
            min-height: 9px;
        }

        &.-withCustomItem {
            border-radius: 3px;
            padding: 5px 10px;

            .removeItemIcon {
                &::after,
                &::before {
                    //Note: Color is $dark-blue-40, but hex color code is used due to opacity and overlay of the 'x'
                    background-color: #a2a5b1 !important;
                }
            }
        }
    }

    &__searchItem {
        display: flex;
        background-color: transparent;
        color: $dark-blue-60;
    }

    &__search {
        align-self: center;
        min-width: 20px;
        max-width: 300px;
        outline: none;
        color: $dark-blue-60;
        font-size: 13px;
        overflow-x: hidden;

        input {
            width: 50px;
        }
    }

    &__options {
        opacity: 0;
        z-index: 22;
        position: absolute;
        bottom: 10px;
        width: 100%;
        max-height: 150px;
        min-height: fit-content;
        border: 1px solid $dark-blue-60;
        border-radius: 6px;
        background-color: $white;
        pointer-events: none;
        transform: translateY(100%);
        transition: 0.3s ease-in-out;
        overflow: hidden;
        overflow-y: auto;
        @include scrollbar;

        &.-open {
            opacity: 1;
            bottom: -1px;
            pointer-events: initial;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                position: relative;
                padding: 5px 25px 5px 10px;
                font-size: 13px;
                cursor: pointer;
                word-break: break-all;
                transition: 0.3s ease-in-out;

                &:hover {
                    background-color: $dark-blue-05;
                }

                &.-empty {
                    background-color: transparent;
                    cursor: default;
                }

                &.-selected {
                    color: $light-blue;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 7px;
                        right: 10px;
                        transform: rotate(45deg);
                        width: 5px;
                        height: 10px;
                        border-bottom: 1.5px solid currentColor;
                        border-right: 1.5px solid currentColor;
                    }
                }
            }
        }
    }
}
