// stylelint-disable value-keyword-case
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, monospace !default;
$font-open-sans: 'Open Sans', $font-family-sans-serif;
$font-inter: 'Inter', $font-family-sans-serif;
// stylelint-enable value-keyword-case

$link-color: $light-blue;

$font-size-base: 16px;
$font-weight-base: normal;

$font-size-small: 80% !default;

$color-text-base: $dark-blue;

$line-height-base: 22px !default;

$h1-font-size-mobile: 20px !default;
$h2-font-size-mobile: 24px !default;
$h3-font-size-mobile: 20px !default;
$h4-font-size-mobile: 25px !default;
$h5-font-size-mobile: 20px !default;
$h6-font-size-mobile: 16px !default;

$h1-font-size-tablet: 30px !default;
$h2-font-size-tablet: 24px !default;
$h3-font-size-tablet: 20px !default;
$h4-font-size-tablet: 25px !default;
$h5-font-size-tablet: 20px !default;
$h6-font-size-tablet: 16px !default;

$h1-font-size-desktop: 36px !default;
$h2-font-size-desktop: 24px !default;
$h3-font-size-desktop: 20px !default;
$h4-font-size-desktop: 25px !default;
$h5-font-size-desktop: 20px !default;
$h6-font-size-desktop: 16px !default;

$headings-margin-bottom: 0 !default;
$headings-margin-top: 0 !default;
$headings-font-family: inherit !default;
$headings-color: $dark-blue !default;

// Printing
$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, 'lg') !default;

// Header, Sidebar, Main
$header-height: 60px;
$sidebar-height: calc(100vh - 60px);

$sidebar-width: 290px;
$main-width: calc(100% - 290px);

$sidebar-closed-width: 0;
$main-closed-width: calc(100% - 100px);